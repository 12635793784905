@import "../../styles/mixins";

$text-size-em: 2.5em;
$transition-duration-ms: 250ms;
$transition-timing-fn: ease;
$color-send-btn-hover: #008000;

@mixin effectButton($activeClassModifiers...) {
  background: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: inherit;
  color: inherit;
  margin-top: 1.5rem;
  font-size: $text-size-em;
  transition: all $transition-timing-fn $transition-duration-ms;
  padding: 1.5rem;
  position: relative;
  width: 100%;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    width: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transition: all $transition-timing-fn $transition-duration-ms;
    background-color: $color-send-btn-hover;
    z-index: -1;
  }

  @each $modifier in $activeClassModifiers {
    &.#{$modifier} {
      &:after {
        width: 100%;
      }
    }
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
}

.form {
  padding: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: center;
  justify-content: space-between;

  .btn--submit {
    @include effectButton('btn--blocked', 'btn--success');
  }
}

.form-input {
  border: 0;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: inherit;
  display: block;
  padding: 1.5rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  input,
  textarea,
  select {
    border: none;
    background: transparent;
    font-size: $text-size-em;
    line-height: 1;
    color: inherit;
    display: block;
    box-shadow: 0 0 0;
    width: 100%;

    &:focus {
      color: inherit;
      box-shadow: 0 0 9px -2px inherit;
      text-decoration: none;
    }

    @include input-text-placeholder {
      color: inherit;
      opacity: .75;
    }
  }

  input,
  textarea,
  select {

  }

  textarea {

  }
}

.form-inputs {
  padding-bottom: 3rem;

  * {
    &:focus {
      outline: none;
    }
  }
}
