@import "../../../styles/mixins";

.image-button-container {
  display: block;
  margin: 1em auto 0;
}

.image-button-link {
  transition: all ease 250ms;
  opacity: .75;
  @include basicLink();

  &:hover {
    opacity: 1;
  }
}

.image-button-placeholder {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  height: 100%;
}
