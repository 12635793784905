.view-container {
  display: flex;
  position: absolute;
  transition: all 250ms ease;
  left: 0;
  top: 0;
  align-self: stretch;
  height: 100%;
  flex: 1;
  width: 100%;
  overflow-x: hidden;
}

.view-container--sidebar {

}

.view-container-content {
  display: flex;
  height: 100%;
  flex: 1;
  position: relative;
  z-index: 2;
  box-shadow: 0 0 9px -2px #000;
  overflow-y: auto;
}

.hamburger-toggle {
  position: absolute;
  left: 1.5rem;
  top: 1.5rem;
  transition: all ease 250ms;
  z-index: 3;
}
