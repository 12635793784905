// Scaffolding
// -------------------------

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 45px;
  line-height: 45px;

  p, ul {
    margin-bottom: 0;
  }
}

// Input Fields
// -------------------------

textarea {
  resize: vertical;
}

// Navbar
// -------------------------

.navbar-nav img {
  width: 30px;
  height: 30px;
  margin: -15px 15px -15px;
}

// Alerts
// -------------------------

.alert {
  margin-top: 20px;
}

// Thumbnails
// -------------------------

.thumbnail {
  padding: 0;
  border-radius: 0;
  box-shadow: 0 0 5px #ccc, inset 0 0 0 #000;
}

// Font Icons
// -------------------------

.fa {
  margin-right: 6px;
  font-family: sans-serif, FontAwesome;
}

