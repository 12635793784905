@mixin input-text-placeholder {
  &::-webkit-input-placeholder { /* Edge */
    @content
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    @content
  }

  &::placeholder {
    @content
  }
}


@mixin basicLink {
  text-decoration: none;

  &:focus {
    -webkit-appearance: none;
  }
}
