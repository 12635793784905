html {
  position: relative;
  min-height: 100%;
}

body {
  padding-top: 60px;
  margin-bottom: 75px;

  &.no-js {
    display: none
  }
}

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-family: 'Open Sans', sans-serif;
  //font-family: 'Lato', sans-serif;
  font-family: 'Montserrat', sans-serif;
  //font-family: 'Playfair Display', serif;
}
