.contact-page {
  display: table;
  padding-top: 5em;
  width: 100%;
  min-height: 100%;
}

.form--contact {
  display: table-cell;
  vertical-align: middle;
}

.home-button {
  position: absolute;
  top: 1.5em;
  left: 0;
  right: 0;
  margin: 0 auto;
}
