.animated-phrase {
  cursor: pointer;
  display: inline-block;
  transition: all;
}

.animated-phrase-child {
  display: inline-block;
  margin-left: 2px;
  text-align: center;
  transition: all;
}

.animated-phrase-child--first {
  margin-left: 0;
  text-align: right;
}

.animated-phrase-child--last {
  text-align: left;
}

.animated-phrase--link {
  text-decoration: none;
  color: inherit;
  line-height: 1;

  &:after {
    content: '';
    display: block;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: inherit;
    opacity: 0;
  }

  &.cta {
    &:after {
      transition: opacity ease 500ms;
      opacity: 1;
    }
  }
}

.animated-word {
  display: inline-block;

  &:before {
    content: '.';
    margin-right: 2px;
    display: inline-block;
  }

  &:first-child {
    &:before {
      content: '';
    }
  }
}

.animated-word--empty {
  &:before {
    content: '';
  }
}
